import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import Axios from "axios";
import useLogout from "./Componentes/useLogout";
import config from './Componentes/config';

function UserPage({ cookies }) {
  const [listadoVacaciones, setlistadoVacaciones] = useState([]); // Estado para guardar las solicitudes
  const logout = useLogout(); // Obtén la función logout
  const [permisosModulos, setPermisosModulos] = useState([]);
  const userId = cookies.userId;
  // Función para obtener las solicitudes de vacaciones
  const getSolicitudesVacaciones = () => {
    console.log("intentando cargar solicitudes de vacaciones panel adm");
    Axios.get(`${config.API_URL}/cargaSolicitudesVacaciones`)
      .then(res => {
        console.log(res.data); // Verifica la estructura de la respuesta
        if (Array.isArray(res.data)) {
          setlistadoVacaciones(res.data); // Asegúrate de que sea un array
        } else {
          console.error('La respuesta no es un array:', res.data);
          setlistadoVacaciones([]); // Establece un array vacío si no es un array
        }
      })
      .catch(err => {
        console.error("Error al obtener los datos:", err);
      });
  };

  // Cargar solicitudes de vacaciones cuando el componente se monta
  useEffect(() => {
    getSolicitudesVacaciones();
  }, []); // Llamar solo una vez al montar el componente

  useEffect(() => {
    console.log("Cookie userId:", userId); // Verifica que se obtiene correctamente
    if (userId) {
      // Obtener permisos de los módulos del usuario
      Axios.get(`${config.API_URL}/getPermisosModulos`, { params: { userId } })
        .then((response) => {
          setPermisosModulos(response.data.map(modulo => modulo.Modulo)); // Guardar los nombres de los módulos permitidos
        })
        .catch((error) => {
          console.error("Error al cargar los permisos:", error);
        });
    } else {
      console.error("No se encontró userId en las cookies");
    }
  }, [userId]);

  const hasPermission = (modulo) => permisosModulos.includes(modulo);

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
        <i className="fa fa-bars"></i>
      </button>
      <ul className="navbar-nav ml-auto">
        {/* Verifica si el usuario tiene permiso para ver las notificaciones */}
        {hasPermission("Administracion") && (
          <li className="nav-item dropdown no-arrow mx-1">
            <Dropdown>
              <Dropdown.Toggle
                as="a"
                className="nav-link dropdown-toggle"
                href="#"
                id="alertsDropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-bell fa-fw"></i>
                {/* Counter - Alerts */}
                <span className="badge badge-danger badge-counter">
                  {listadoVacaciones.length > 0 ? `${listadoVacaciones.length}+` : "0"}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="dropdown-list dropdown-menu dropdown-menu-right shadow"
                aria-labelledby="alertsDropdown"
                style={{
                  position: "absolute",
                  top: "100%", // Asegura que el dropdown se muestre justo debajo
                  left: "0", // Alinea el dropdown con el botón de alerta
                  zIndex: "1050", // Asegura que el menú esté por encima de otros elementos
                  minWidth: "250px", // Ajusta el ancho del menú si es necesario
                  animation: "none", // Desactiva cualquier animación que pueda causar el salto
                }}
              >
                <h6 className="dropdown-header">Alertas</h6>

                {/* Mapea las alertas para generarlas dinámicamente */}
                {listadoVacaciones.length > 0 ? (
                  listadoVacaciones.map((alerta, index) => (
                    <a key={index} className="dropdown-item d-flex align-items-center" href="/Administracion">
                      <div className="mr-3">
                        <div className="icon-circle bg-warning">
                          <i className="fas fa-exclamation-triangle text-white"></i>
                        </div>
                      </div>
                      <div>
                        <div className="small text-gray-500">{new Date(alerta.Fecha_Solicitud).toLocaleDateString()}</div>
                        <span>
                          {`Atención ${alerta.Nombre_Completo} ha solicitado ${alerta.Dias_Solicitados} días de vacaciones.`}
                        </span>
                      </div>
                    </a>
                  ))
                ) : (
                  <span className="dropdown-item text-center small text-gray-500">
                    No hay solicitudes pendientes.
                  </span>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </li>
        )}

        <div className="topbar-divider d-none d-sm-block"></div>

        {/* Dropdown para el perfil del usuario */}
        <Dropdown>
          <Dropdown.Toggle variant="link" id="dropdown-basic">
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">{cookies.usuario}</span>
            <img
              className="img-profile"
              src="/img/perfilM.svg"
              alt="Profile"
              style={{
                width: "40px",
                height: "40px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={logout}>
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i> Salir
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </ul>
    </nav>
  );
}

export default UserPage;
