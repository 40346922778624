// /Validations/TrabajadoresValidation.js
export const validarCampos = (campos) => {
  const {
    empresa, nombres, apellidos, nacionalidad, fechaNacimiento, estadoCivil,
    afp, rut, prevision, domicilio, comuna, cargo,
    tipoContrato, vigenciaDesde, vigenciaHasta
  } = campos;

  if (
    !empresa || !nombres || !apellidos || !nacionalidad || !fechaNacimiento ||
    !estadoCivil || afp === "none" || afp === "Seleccione:" || !rut || prevision === "none" ||
    prevision === "Seleccione:" || !domicilio || !comuna || !cargo ||
    tipoContrato === "none" || tipoContrato === "Seleccione:" || !vigenciaDesde ||
    (tipoContrato !== "Indefinido" && !vigenciaHasta)
  ) {
    return false;
  }
  return true;
};

// /Validations/ArchivoValidation.js
export const validarArchivo = (selectedFile, TipoArchivo) => {
  if (!selectedFile) {
    return 'Por favor, selecciona un archivo antes de subir.';
  }

  if (!TipoArchivo || TipoArchivo === "Seleccione:") {
    return 'Por favor, selecciona un tipo de archivo válido.';
  }

  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const formatRut = (value) => {
  // Remueve cualquier caracter que no sea número o 'K' (dígito verificador)
  value = value.replace(/[^0-9kK]/g, '').toUpperCase();

  // Inserta el guion antes del dígito verificador
  if (value.length > 1) {
    value = value.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '-' + value.slice(-1);
  }
  return value;
};

export const formatRutEmpresa = (rut) => {
  const rutString = rut.toString();
  // Extrae el dígito verificador (último dígito) y los demás números
  const body = rutString.slice(0, -1);
  const verifier = rutString.slice(-1);
  // Aplica puntos cada tres dígitos al cuerpo del RUT
  const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  // Retorna el RUT formateado con el guion antes del dígito verificador
  return `${formattedBody}-${verifier}`;
};

export const formatRutDV = (value) => {
  // Asegurarse de que el valor sea un número entero
  const rut = parseInt(value, 10);

  // Realizar la operación para obtener el dígito verificador
  let suma = 0;
  let multiplicador = 2;

  // Recorre el RUT de derecha a izquierda, multiplicando los dígitos por el factor
  for (let i = rut.toString().length - 1; i >= 0; i--) {
    suma += parseInt(rut.toString()[i]) * multiplicador;
    multiplicador = multiplicador === 7 ? 2 : multiplicador + 1;
  }

  // Calcular el dígito verificador
  const dv = 11 - (suma % 11);

  // Determinar el dígito verificador (si es 11 es '0', si es 10 es 'K')
  const digitoVerificador = dv === 11 ? '0' : (dv === 10 ? 'K' : dv.toString());

  // Formatear el RUT con puntos para los miles
  const rutFormateado = rut.toLocaleString('es-CL');

  // Retornar el RUT con el dígito verificador y con los puntos
  return `${rutFormateado}-${digitoVerificador}`;
};

export const cleanRut = (formattedRut) => {
  return formattedRut.replace(/\D/g, '').slice(0, -1); // Remueve caracteres no numéricos y el dígito verificador
};

export const formatRemuneracion = (value) => {
  // Verifica si el valor es undefined o null, y si es así, asigna una cadena vacía
  if (value == null) {
    value = '';  // Asigna un valor vacío si value es null o undefined
  }

  // Si value no es una cadena, lo convierte a cadena
  if (typeof value !== 'string') {
    value = value.toString();
  }

  // Remueve cualquier caracter que no sea número
  value = value.replace(/\D/g, '');

  // Formatea en miles
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};


export const cleanRemuneracion = (formattedRemuneracion) => {
  return formattedRemuneracion.replace(/\./g, ''); // Elimina puntos
};

export const cleanRemuneracion2 = (formattedRemuneracion) => {
  // Aseguramos que el valor sea una cadena, eliminamos puntos y luego lo convertimos a número
  const valueStr = formattedRemuneracion.toString().replace(/\./g, ''); // Elimina puntos
  return parseFloat(valueStr); // Convierte a número
};

export const validarCompensado = (Fecha, TipoCompensado) => {
  if (!Fecha) {
    return 'Por favor, seleccione la fecha del compensado!';
  }

  if (!TipoCompensado || TipoCompensado === "Seleccione:") {
    return 'Por favor, seleccione el tipo de compensado.';
  }

  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const validarPermiso = (Fecha, tipoPermiso, HoraDesde, HoraHasta) => {
  if (!Fecha) {
    return 'Por favor, seleccione la fecha del permiso!';
  }

  if (!tipoPermiso || tipoPermiso === "Seleccione:") {
    return 'Por favor, seleccione el tipo de permiso.';
  }

  if (tipoPermiso === "RH") {
    if (!HoraDesde) {
      return 'Por favor, seleccione la hora de inicio del permiso!';
    }

    if (!HoraHasta) {
      return 'Por favor, seleccione la hora de termino del permiso!';
    }
  }
  
  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const validarCotizacion = (idEmpresa, NombreEmpresa, Direccion, RutEmpresa, SueldoBase, Gratificacion, Movilizacion, Administracion, Indemnizacion, ValorHextra, CantHextra, ValorColacion, CantColacion) => {
  if (!idEmpresa) {
    return 'Empresa no seleccionada!';
  }

  if (!NombreEmpresa) {
    return 'Por favor, completa la informacion de empresa de la cotizacion';
  }

  if (!Direccion) {
    return 'Por favor, completa la informacion de direccion de la cotizacion';
  }
  
  if (!RutEmpresa) {
    return 'Por favor, completa la informacion de Rut empresa de la cotizacion';
  }

  if (!SueldoBase) {
    return 'Por favor, completa la informacion de sueldo base de la cotizacion';
  }

  if (!Gratificacion) {
    return 'Por favor, completa la informacion de gratificacion de la cotizacion';
  }

  if (!Movilizacion) {
    return 'Por favor, completa la informacion de movilizacion de la cotizacion';
  }

  if (!Administracion) {
    return 'Por favor, completa la informacion de administracion de la cotizacion';
  }

  if (!Indemnizacion) {
    return 'Por favor, completa la informacion de Indemnizacion de la cotizacion';
  }

  if (!ValorHextra) {
    return 'Por favor, completa la informacion de valor hora extra de la cotizacion';
  }

   if (!CantHextra) {
    return 'Por favor, completa la informacion de cantidad hora extra de la cotizacion';
  }

  if (!ValorColacion) {
    return 'Por favor, completa la informacion de valor colacion de la cotizacion';
  }

  if (!CantColacion) {
    return 'Por favor, completa la informacion de cantidad colacion de la cotizacion';
  }
  
  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const validarCotizacionVenta = (idEmpresa, NombreEmpresa, Direccion, RutEmpresa, Duracion, CondicionPago, Titulo, Utilidad) => {
  if (!idEmpresa) {
    return 'Empresa no seleccionada!';
  }

  if (!NombreEmpresa) {
    return 'Por favor, completa la informacion de empresa de la cotizacion';
  }

  if (!Direccion) {
    return 'Por favor, completa la informacion de direccion de la cotizacion';
  }
  
  if (!RutEmpresa) {
    return 'Por favor, completa la informacion de Rut empresa de la cotizacion';
  }

  if (!Duracion) {
    return 'Por favor, completa la informacion de sueldo base de la cotizacion';
  }

  if (!CondicionPago) {
    return 'Por favor, completa la informacion de condición de pago de la cotizacion';
  }

  if (!Titulo) {
    return 'Por favor, completa la informacion de Titulo de la cotizacion';
  }

  if (!Utilidad) {
    return 'Por favor, completa la información de utilidad';
  }
  
  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};

export const validarVacaciones = (FechaInicio, FechaFin, diasDisponibles, diasSolicitados) => {
  if (!FechaInicio) {
    return 'Por favor, seleccione la fecha de inicio!';
  }

  if (!FechaFin) {
    return 'Por favor, seleccione la fecha de termino!.';
  }

  if (diasSolicitados > diasDisponibles){
    return 'Los dias solicitados exceden los dias disponibles!';
  }

  return ''; // Retorna una cadena vacía si todas las validaciones pasan
};