import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import './ccss/App.css';
import { formatRemuneracion, cleanRemuneracion } from './Validations/TrabajadoresValidation';
import { generarPDF } from "./Documentos/Cotizacion";
import { registrarLog } from './Componentes/logService';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { validarCotizacion } from './Validations/TrabajadoresValidation';
import config from './Componentes/config';
import moment from 'moment';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png",
    INVE: "/img/idafaLogo.png",
    TRAN: "/img/extasisLogo.png"
};


function Cotizaciones() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const [listadoCotizacion, setListadoCotizacion] = useState([]);
    const [cookiesEmpresa] = useCookies(['idEmpresa']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const [editar, setEditar] = useState(false);
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    const [rows, setRows] = useState([
        { Descripcion: "", ValorTotal: "", Cantidad: "", valorNeto: "", iva: "", valorMes: "" }
    ]);

    const agregarFila = () => {
        setRows([
            ...rows,
            { Descripcion: "", Cantidad: "", ValorTotal: "", valorNeto: "", iva: "", valorMes: "" },
        ]);
    };

    // Actualizar valores de la fila
    const actualizarFila = (index, columna, valor) => {
        const nuevasFilas = [...rows];
        const fila = nuevasFilas[index];

        // Actualizar columna ingresada por el usuario
        fila[columna] = valor;

        // Verificar si 'ValorTotal' es una cadena antes de aplicar replace
        const valorTotal = typeof fila.ValorTotal === "string"
            ? Number(fila.ValorTotal.replace(".", "").replace("$", ""))
            : Number(fila.ValorTotal) || 0; // Si no es string, convertir directamente a número

        const cantidad = Number(fila.Cantidad) || 0;

        // Recalcular las columnas dependientes si "ValorTotal" o "Cantidad" cambian
        if (columna === "ValorTotal" || columna === "Cantidad") {
            const valorNeto = (valorTotal * cantidad) / 12;
            const iva = valorNeto * 0.19;

            fila.valorNeto = formatRemuneracion(Math.round(valorNeto).toString());
            fila.iva = formatRemuneracion(Math.round(iva).toString());
            fila.valorMes = formatRemuneracion(Math.round(valorNeto + iva).toString());
        }

        setRows(nuevasFilas); // Actualiza el estado con las filas modificadas
    };

    const calcularTotalMes = () => {
        return rows.reduce((total, row) => {
            // Verifica si row.valorMes es una cadena válida antes de aplicar replace
            const valorMes = row.valorMes && typeof row.valorMes === "string"
                ? Number(row.valorMes.replace(/\./g, "").replace(/,/g, "")) // Elimina puntos y comas para convertir en número
                : 0; // Si no es válido, asigna 0
            return total + valorMes;
        }, 0);
    };




    // Estados para el componente Trabajadores
    const empresa = getEmpresaName(cookies.selectedCard);
    const [sueldoBase, setsueldoBase] = useState("");
    const [nombreEmpresa, setnombreEmpresa] = useState("");
    const [direccion, setDireccion] = useState("");
    const [rutempresa, setrutempresa] = useState("");
    const [numerocotizacion, setnumerocotizacion] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setemail] = useState("");
    const [gratificacion, setGratificacion] = useState("");
    const [movilizacion, setMovilizacion] = useState("");
    const [insumos, setInsumos] = useState("");
    const [administracion, setAdministracion] = useState("");
    const [valorHora, setvalorHora] = useState("");
    const [cantidadHora, setcantidadHora] = useState("");
    const [valorColacion, setvalorColacion] = useState("");
    const [cantidadColacion, setcantidadColacion] = useState("");
    const [indemnizacion, setindemnizacion] = useState("");
    const [utilidad, setUtilidad] = useState("");
    const horaextrapp = Number(valorHora) * Number(cantidadHora);
    const TotalColacion = Number(valorColacion) * Number(cantidadColacion);
    const subTotalImponible = Number(sueldoBase) + Number(gratificacion) + horaextrapp;
    const valorSIS = Math.round(subTotalImponible * 0.0186);
    const valorMututal = Math.round(subTotalImponible * 0.0348);
    const valorseguroCesantia = Math.round(subTotalImponible * 0.03);
    const valorCostoTotalNeto = Number(subTotalImponible) + Number(movilizacion) + Number(TotalColacion) + Number(valorSIS) + Number(valorMututal) + Number(valorseguroCesantia);
    const CalculoUtilidad = Math.round((Number(valorCostoTotalNeto) + Number(insumos) + Number(administracion) + Number(indemnizacion) + Number(calcularTotalMes())) * (Number(utilidad) / 100));
    const TotalFijoAdm = Number(valorCostoTotalNeto) + Number(administracion) + Number(indemnizacion) + Number(CalculoUtilidad) + Number(insumos);
    const TotalFijoTrabajador = Number(TotalFijoAdm) + calcularTotalMes()
    const TotalEPP = calcularTotalMes()
    const today = new Date();
    const fechaactual = today.toLocaleDateString('en-GB');
    const [idCotizacion, setidCotizacion] = useState("");
    const datosSueldo = {
        sueldoBase,
        gratificacion,
        horaextrapp,
        subTotalImponible,
        movilizacion,
        TotalColacion,
        valorSIS,
        valorMututal,
        valorseguroCesantia,
        valorCostoTotalNeto,
        administracion,
        indemnizacion,
        TotalEPP,
        utilidad,
        CalculoUtilidad,
        TotalFijoTrabajador,
        nombreEmpresa,
        fechaactual,
        empresa,
        direccion,
        numerocotizacion,
        rutempresa,
        telefono,
        email,
        idCotizacion,
        cantidadHora
    };

    const rows2 = rows.map(row => ({
        descripcion: row.Descripcion,
        valorTotal: row.ValorTotal, // Descripción de la fila
        cantidad: row.Cantidad,        // Cantidad de la fila
        valorNeto: row.valorNeto,
        valorIVA: row.iva,  // Valor neto formateado con 2 decimales
        valorMes: row.valorMes,    // Valor mes formateado con 2 decimales
    }));

    const eliminarFila = async (index, id) => {       
        try {
            const res = await Swal.fire({
              title: '¡Cuidado!',
              html: `¿Está seguro de eliminar el item de la cotización?`,
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'Cancelar',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sí, eliminar!'
            });
        
            if (res.isConfirmed) {
              await Axios.delete(`${config.API_URL}/EliminaItemCotizacionOP/${id}`);     
              // Registrar el log
              await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion operario',
                Detalle: `Delete item de Cotizacion operario: ${id}`,
                Estado: 'Éxito',
              });
        
              // Eliminar la fila de la tabla
              const nuevasFilas = rows.filter((_, i) => i !== index);
              setRows(nuevasFilas);       
              Swal.fire(
                'Eliminado!',
                'El item de la cotizacion ha sido eliminado exitosamente',
                'success'
              );
            }
          } catch (error) {
            console.error('Error al eliminar la cotización:', error);
            await registrarLog({
              Usuario: cookies.usuario,
              Modulo: 'Cotizacion operario',
              Detalle: `Delete de item Cotizacion operario: ${id} - error ${error}`,
              Estado: 'Fallo',
            });
            Swal.fire(
              '¡Algo pasó!',
              'Hubo un error al eliminar el item de la cotización. Intente nuevamente.',
              'error'
            );
          }
    };

    const limpiarCampos = () => {
        setAdministracion("");
        setDireccion("");
        setGratificacion("");
        setInsumos("");
        setMovilizacion("");
        setTelefono("");
        setUtilidad("");
        setcantidadColacion("");
        setcantidadHora("");
        setemail("");
        setindemnizacion("");
        setnombreEmpresa("");
        setrutempresa("");
        setsueldoBase("");
        setvalorHora("");
        setvalorColacion("");
        setEditar(false);
      }


    const RegistraCotizacion = async () => {
        const mensajeError = validarCotizacion(cookies.idEmpresa, nombreEmpresa, direccion, rutempresa, sueldoBase, gratificacion, movilizacion, administracion, indemnizacion,
            valorHora, cantidadHora, valorColacion, cantidadColacion);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idEmpresa: cookiesEmpresa.idEmpresa,
            NombreEmpresa: nombreEmpresa,
            Direccion: direccion,
            RutEmpresa: rutempresa,
            Telefono: telefono,
            Email: email,
            SueldoBase: sueldoBase,
            Gratificacion: gratificacion,
            Movilizacion: movilizacion,
            Administracion: administracion,
            Indemnizacion: indemnizacion,
            ValorHextra: valorHora,
            CantHextra: cantidadHora,
            ValorColacion: valorColacion,
            CantColacion: cantidadColacion,
            Insumos: insumos,
            Utilidad: utilidad,
            Usuario: cookies.userId
        };
        try {
            const response = await Axios.post(`${config.API_URL}/RegistraCotizacionOperario`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const idGenerado = response.data.idGenerado;
            setidCotizacion(idGenerado);
            guardarTabla(idGenerado);
            // Registrar el log (llamamos a la función desacoplada)
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `Registro de cotizacion`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'La cotizacion se registro exitosamente',
                'success'
            );
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `Registro de cotizacion - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    }

    const guardarTabla = async (idGenerado) => {
        try {
            const respuesta = await Axios.post(`${config.API_URL}/guardarTabla`, {
                idCotizacion: idGenerado, // Aquí podrías pasar un ID dinámico
                datos: rows.map(row => ({
                    descripcion: row.Descripcion,
                    valorTotal: row.ValorTotal
                        ? Number(row.ValorTotal.toString().replace(/[$.]/g, "")) || 0
                        : 0, // Si valorTotal es undefined o null, asignamos 0
                    cantidad: Number(row.Cantidad) || 0
                }))
            });
            // Agregar un mensaje de éxito o lo que necesites hacer con la respuesta
        } catch (error) {
            console.error(error);
            Swal.fire("Error", "Ocurrió un error al guardar la tabla.", "error");
        }
    };

    const ActualizarTabla = async (idGenerado) => { 
        try {
            // Prepara los datos de la tabla para el envío
            const datos = rows.map(row => ({
                ID: row.ID,
                descripcion: row.Descripcion || "",  // Asigna un valor predeterminado si falta
                valorTotal: row.ValorTotal
                    ? Number(row.ValorTotal.toString().replace(/[$.]/g, "")) || 0
                    : 0,  // Convierte el valor a número sin los símbolos de moneda
                cantidad: Number(row.Cantidad) || 0  // Asegura que la cantidad sea un número
            }));
    
            // Realiza la solicitud de guardado
            const respuesta = await Axios.post(`${config.API_URL}/ActualizarTablaOP`, {
                idCotizacion: idGenerado,  // Pasa el idGenerado de forma dinámica
                datos  // Envía los datos a la API
            });
    
            // Aquí puedes agregar cualquier acción con la respuesta, por ejemplo, mostrar un mensaje de éxito.
            console.log(respuesta.data);
            Swal.fire("Éxito", "La tabla se ha guardado correctamente.", "success");
        } catch (error) {
            console.error(error);
            Swal.fire("Error", "Ocurrió un error al guardar la tabla.", "error");
        }
    };
    
    const cargaCotizacion = () => {
        const idEmpresa = cookiesEmpresa.idEmpresa;
        Axios.get(`${config.API_URL}/cargaCotizacion/${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setListadoCotizacion(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setListadoCotizacion([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const obtenerCotizacion = async (idCotizacion) => {
        try {
            const response = await Axios.get(`${config.API_URL}/obtenerCotizacion/${idCotizacion}`);
            if (response.data.success) {
                setEditar(true);
                const { cotizacion, costosVariables } = response.data;
                console.log("Datos de la cotización:", response.data.cotizacion);
                console.log("Costos variables:", response.data.costosVariables);
                setnombreEmpresa(cotizacion.NombreEmpresa);
                setDireccion(cotizacion.Direccion);
                setrutempresa(cotizacion.RutEmpresa);
                setTelefono(cotizacion.Telefono);
                setemail(cotizacion.Email);
                setsueldoBase(cotizacion.SueldoBase.toString());
                setGratificacion(cotizacion.Gratificacion.toString());
                setMovilizacion(cotizacion.Movilizacion.toString());
                setAdministracion(cotizacion.Administracion.toString());
                setindemnizacion(cotizacion.Indemnizacion.toString());
                setvalorHora(cotizacion.ValorHextra.toString());
                setcantidadHora(cotizacion.CantHextra.toString());
                setvalorColacion(cotizacion.ValorColacion.toString());
                setcantidadColacion(cotizacion.CantColacion.toString());
                setInsumos(cotizacion.Insumos.toString());
                setUtilidad(cotizacion.Utilidad);
                setidCotizacion(cotizacion.ID_Cotizacion);
                setRows(costosVariables);
                actualizarFilasDescripcion(costosVariables);
            } else {
                console.error("Error:", response.data.message);
            }
        } catch (error) {
            console.error("Error al obtener la cotización:", error);
        }
    };

    const UpdateCotizacion = async () => {
        const mensajeError = validarCotizacion(cookies.idEmpresa, nombreEmpresa, direccion, rutempresa, sueldoBase, gratificacion, movilizacion, administracion, indemnizacion,
            valorHora, cantidadHora, valorColacion, cantidadColacion);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idCotizacion: idCotizacion,
            idEmpresa: cookiesEmpresa.idEmpresa,
            NombreEmpresa: nombreEmpresa,
            Direccion: direccion,
            RutEmpresa: rutempresa,
            Telefono: telefono,
            Email: email,
            SueldoBase: sueldoBase,
            Gratificacion: gratificacion,
            Movilizacion: movilizacion,
            Administracion: administracion,
            Indemnizacion: indemnizacion,
            ValorHextra: valorHora,
            CantHextra: cantidadHora,
            ValorColacion: valorColacion,
            CantColacion: cantidadColacion,
            Insumos: insumos,
            Utilidad: utilidad,
            Usuario: cookies.userId
        };
        try {
            const response = await Axios.post(`${config.API_URL}/UpdateCotizacionOperario`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            // const idGenerado = response.data.idGenerado;
            // setidCotizacion(idGenerado);
            // guardarTabla(idGenerado);
            // Registrar el log (llamamos a la función desacoplada)
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `actualizacion de cotizacion`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'La cotizacion se actualizo exitosamente',
                'success'
            );
            ActualizarTabla(idCotizacion);
            limpiarCampos();
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Cotizacion',
                Detalle: `Actualizacion de cotizacion - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
      };

    const actualizarFilasDescripcion = (filas) => {
        if (filas && filas.length > 0) {
            const datosActualizados = filas.map((fila) => {
                const valorTotal = typeof fila.ValorTotal === "string"
                    ? Number(fila.ValorTotal.replace(".", "").replace("$", ""))
                    : Number(fila.ValorTotal) || 0;

                const cantidad = Number(fila.Cantidad) || 0;

                // Recalcular columnas dependientes
                const valorNeto = (valorTotal * cantidad) / 12;
                const iva = valorNeto * 0.19;
                const valorMes = valorNeto + iva;

                return {
                    ...fila,
                    valorNeto: formatRemuneracion(Math.round(valorNeto).toString()),
                    iva: formatRemuneracion(Math.round(iva).toString()),
                    valorMes: formatRemuneracion(Math.round(valorMes).toString()),
                };
            });

            setRows(datosActualizados);
        }
    };

    const DeleteCotizacion = async (id) => {
        try {
          const res = await Swal.fire({
            title: '¡Cuidado!',
            html: '¿Está seguro de eliminar la cotización?.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!'
          });
      
          if (res.isConfirmed) {
            await Axios.delete(`${config.API_URL}/EliminarCotizacion/${id}`);
            await registrarLog({
              Usuario: cookies.usuario,
              Modulo: 'Cotizacion de operario',
              Detalle: `Delete de Cotizacion operario: ${id}`,
              Estado: 'Éxito',
            });
            cargaCotizacion();
            limpiarCampos();
            
            Swal.fire(
              'Eliminado!',
              'La cotización se ha sido eliminado exitosamente',
              'success'
            );
          }
        } catch (error) {
          console.error('Error al eliminar la cotizacion:', error);
          await registrarLog({
            Usuario: cookies.usuario,
            Modulo: 'Cotizacion de operario',
            Detalle: `Delete de Cotizacion operario: ${id} - error ${error}`,
            Estado: 'Fallo',
          });
          Swal.fire(
            '¡Algo pasó!',
            'Hubo un error al eliminar la cotización. Intente nuevamente.',
            'error'
          );
        }
      }



    useEffect(() => {
        cargaCotizacion();
    }, []);


    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Crear cotizaciones para {empresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            {/* <select class="form-select">
                                                <option value="">Selecione:</option>
                                                <option value="">Costo operario</option>
                                                <option value="">Costo operario</option>
                                            </select> */}
                                            <div class="input-group mb-3">
                                                <label class="form-label">Costos fijos operario:</label>
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Nombre empresa:</span>
                                                <input type="text" class="form-control" value={nombreEmpresa} onChange={(event) => { setnombreEmpresa(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Direccion:</span>
                                                <input type="text" class="form-control" value={direccion} onChange={(event) => { setDireccion(event.target.value); }} />

                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">RUT:</span>
                                                <input type="text" class="form-control" value={rutempresa} onChange={(event) => { setrutempresa(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Telefono:</span>
                                                <input type="text" class="form-control" value={telefono} onChange={(event) => { setTelefono(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">E-mail:</span>
                                                <input type="text" class="form-control" value={email} onChange={(event) => { setemail(event.target.value); }} />
                                            </div>
                                            <hr />
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Sueldo base:</span>
                                                <input type="text" class="form-control" value={sueldoBase} onChange={(event) => { setsueldoBase(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Gratificación:</span>
                                                <input type="text" class="form-control" value={gratificacion} onChange={(event) => { setGratificacion(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Movilización:</span>
                                                <input type="text" class="form-control" value={movilizacion} onChange={(event) => { setMovilizacion(event.target.value); }} />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Adm general:</span>
                                                <input type="text" class="form-control" value={administracion} onChange={(event) => { setAdministracion(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Indm y aguinaldo:</span>
                                                <input type="text" class="form-control" value={indemnizacion} onChange={(event) => { setindemnizacion(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Valor hora extra:</span>
                                                <input type="text" class="form-control" value={valorHora} onChange={(event) => { setvalorHora(event.target.value); }} />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Cant hora extra:</span>
                                                <input type="text" class="form-control" value={cantidadHora} onChange={(event) => { setcantidadHora(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Valor colacion:</span>
                                                <input type="text" class="form-control" value={valorColacion} onChange={(event) => { setvalorColacion(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Cant colacion:</span>
                                                <input type="text" class="form-control" value={cantidadColacion} onChange={(event) => { setcantidadColacion(event.target.value); }} />
                                            </div>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Utilidad %:</span>
                                                <input type="text" class="form-control" value={utilidad} onChange={(event) => { setUtilidad(event.target.value); }} />
                                                <span class="input-group-text" id="basic-addon1">Insumos:</span>
                                                <input type="text" class="form-control" value={insumos} onChange={(event) => { setInsumos(event.target.value); }} />
                                            </div>
                                            <div class="mb-3 table-container">
                                                <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>SUELDO {cantidadHora} HORAS EXTRAS</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Sueldo base</td>
                                                            <td>${formatRemuneracion(sueldoBase)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Gratificación</td>
                                                            <td>${formatRemuneracion(gratificacion)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Horas extra pp</td>
                                                            <td>${formatRemuneracion(horaextrapp.toString())}</td>
                                                        </tr>
                                                        <tr className="subtotal-imponible">
                                                            <td>SubTotal imponible</td>
                                                            <td>${formatRemuneracion(subTotalImponible.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Movilización</td>
                                                            <td>${formatRemuneracion(movilizacion)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Colación</td>
                                                            <td>${formatRemuneracion(TotalColacion.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SIS</td>
                                                            <td>${formatRemuneracion(valorSIS.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Mutual</td>
                                                            <td>${formatRemuneracion(valorMututal.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Seguro Cesantia</td>
                                                            <td>${formatRemuneracion(valorseguroCesantia.toString())}</td>
                                                        </tr>
                                                        <tr className="Costo-Neto">
                                                            <td>COSTO TOTAL NETO</td>
                                                            <td>${formatRemuneracion(valorCostoTotalNeto.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Administración general</td>
                                                            <td>${formatRemuneracion(administracion.toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Indemnizacion del trabajador y aguinaldo</td>
                                                            <td>${formatRemuneracion(indemnizacion.toString())}</td>
                                                        </tr>
                                                        <tr className="Costo-EPP">
                                                            <td>COSTO VARIABLE EPP</td>
                                                            <td>${formatRemuneracion(calcularTotalMes().toString())}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Insumos</td>
                                                            <td>${formatRemuneracion(insumos)}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Utilidad {utilidad}%</td>
                                                            <td>${formatRemuneracion(CalculoUtilidad.toString())}</td>
                                                        </tr>
                                                        <tr className="Costo-Ingreso">
                                                            <td>COSTO TOTAL INGRESO</td>
                                                            <td>${formatRemuneracion(TotalFijoTrabajador.toString())}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <label>Costos variables</label>
                                            <div class="mb-3 table-container">
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Descripcion</th>
                                                            <th>Valor Total</th>
                                                            <th>Cantidad</th>
                                                            <th>Valor Neto</th>
                                                            <th>IVA</th>
                                                            <th>Valor Mes</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {rows.map((row, index) => (
                                                            <tr key={index}>
                                                                <td>{row.ID}</td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={row.Descripcion || ""}
                                                                        onChange={(e) =>
                                                                            actualizarFila(index, "Descripcion", e.target.value)
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        value={"$" + formatRemuneracion(row.ValorTotal)}
                                                                        onChange={(e) =>
                                                                            actualizarFila(index, "ValorTotal", e.target.value)
                                                                        }
                                                                    />
                                                                </td>
                                                                <td> <input
                                                                    value={row.Cantidad}
                                                                    onChange={(e) =>
                                                                        actualizarFila(index, "Cantidad", e.target.value)
                                                                    }
                                                                /></td>
                                                                <td>${row.valorNeto}</td>
                                                                <td>${row.iva}</td>
                                                                <td>${row.valorMes}</td>
                                                                <td>
                                                                    <button onClick={() => eliminarFila(index, row.ID)} className="btn btn-danger btn-sm">
                                                                        Eliminar
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td colSpan="6" style={{ textAlign: "center", fontWeight: "bold" }}>
                                                                Total costo ingreso ${formatRemuneracion(calcularTotalMes().toString())}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <button onClick={agregarFila} class="btn btn-secondary">Agregar Fila</button>
                                                {editar ? (
                                                    <div>
                                                        <button className='btn btn-danger m-2' onClick={limpiarCampos}>Cancelar</button>
                                                        <button onClick={UpdateCotizacion} class="btn btn-secondary">Guardar cotizacion</button>
                                                    </div>
                                                ) : (
                                                    <button onClick={RegistraCotizacion} class="btn btn-success">Registrar cotizacion</button>
                                                )}
                                                <button class="btn btn-primary" onClick={() => generarPDF(rows2, datosSueldo)}>Exportar PDF</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de cotizaciones</h6>
                                        </div>
                                        <div class="card-body">
                                            {
                                                <div>
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                            <thead>
                                                                <tr>
                                                                    <th>N°</th>
                                                                    <th>Empresa</th>
                                                                    <th>Rut empresa</th>
                                                                    <th>Email</th>
                                                                    <th>Fecha creación</th>
                                                                    <th>Usuario Crea</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Array.isArray(listadoCotizacion) && listadoCotizacion.length > 0 ? (
                                                                        listadoCotizacion.map((val, key) => (
                                                                            <tr key={key}>
                                                                                <th scope="row">{val.ID_Cotizacion}</th>
                                                                                <td data-label="NombreEmpresa">{val.NombreEmpresa}</td>
                                                                                <td data-label="RutEmpresa">{val.RutEmpresa}</td>
                                                                                <td data-label="Email">{val.Email}</td>
                                                                                <td data-label="FechaCrea">{moment(val.FechaCreacion).format('DD/MM/YYYY')}</td>
                                                                                <td data-label="UsuarioCrea">{val.Usuario}</td>
                                                                                <td data-label="Acciones">
                                                                                    <div className="btn-group" role="group" aria-label="Basic example">
                                                                                        <button type="button" onClick={() => { obtenerCotizacion(val.ID_Cotizacion); }} className="btn btn-info btn-sm">Seleccionar</button>
                                                                                        <button type="button" onClick={() => DeleteCotizacion(val.ID_Cotizacion)} className="btn btn-danger btn-sm">Eliminar</button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr><td colSpan="15">No hay información de compensados para el trabajador seleccionado.</td></tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Cotizaciones
