import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import Swal from 'sweetalert2';
import { validarCompensado } from './Validations/TrabajadoresValidation';
import generarPDF from './Documentos/Compensado';
import config from './Componentes/config';
import useAuthRedirect from "./Componentes/auth";
import moment from 'moment';
import { registrarLog } from './Componentes/logService';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png",
    INVE: "/img/idafaLogo.png",
    TRAN: "/img/extasisLogo.png"
};

const cardImagesPDF = {
    DAFA: "/img/dafaLogoPDF.png",
    FUGA: "/img/fugarLogoPDF.png",
    INVE: "/img/idafaLogoPDF.png",
    TRAN: "/img/extasisLogoPDF.png"
};


function Compensados() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const imageUrlPDF = cardImagesPDF[cookies.selectedCard]
    const idEmpresa = cookies.idEmpresa;
    const NombreEmpresa = cookies.NombreEmpresa;
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const [trabajadores, setTrabajadores] = useState([]);
    const [idTrabajadorSeleccionado, setIdTrabajadorSeleccionado] = useState('');
    const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
    const [fechaCompensado, setFechaCompensado] = useState("");
    const [tipoCompensado, settipoCompensado] = useState("");
    const [fechaToma, setfechaToma] = useState("");
    const [obsCompensado, setobsCompensado] = useState("");
    const [listadoCompensado, setListadoCompensado] = useState([]);
    const [seleccion, setSeleccion] = useState(false);
    const [cADD, setCADD] = useState("");
    const [cDEL, setCDEL] = useState("");
    const [cTOTAL, setCTOTAL] = useState("");
    const [editar, setEditar] = useState(false);
    const [idCompensado, setidCompensado] = useState("");

    const ConfirmaRegistro = () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, registrar!'
        }).then((result) => {
            if (result.isConfirmed) {
                RegistraCompensado();
            }
        });
    }

    const seleccionTrue = (ID) => {
        setSeleccion(true);
        console.log('ID:' + ID);
        cargaCompensados(ID);
    }

    const RegistraCompensado = async () => {
        const mensajeError = validarCompensado(fechaCompensado, tipoCompensado);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idTrabajador: idTrabajadorSeleccionado,
            fechaCompensado: fechaCompensado,
            tipoCompensado: tipoCompensado,
            obsCompensado: obsCompensado,
            usuarioRegistro: cookies.usuario,
            fechatoma: fechaToma
        };
        try {
            const response = await Axios.post(`${config.API_URL}/registraCompensado`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Compensados',
                Detalle: `Registro de compensado trabajador ID: ${idTrabajadorSeleccionado}`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'El compensado se registro exitosamente',
                'success'
            );
            cargaCompensados(idTrabajadorSeleccionado);
            setFechaCompensado("");
            settipoCompensado("");
            setobsCompensado("");
            setfechaToma("");
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Compensados',
                Detalle: `Registro de compensado trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    }

    const cargaCompensados = (ID) => {
        Axios.get(`${config.API_URL}/cargaCompensado?id=${ID}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setListadoCompensado(res.data); // Asegúrate de que sea un array
                    const { totalADD, totalDEL } = res.data.reduce((totals, item) => {
                        if (item.TipoCompensado === 'ADD') {
                            totals.totalADD += 1;
                        } else if (item.TipoCompensado === 'DEL') {
                            totals.totalDEL += 1;
                        }
                        return totals;
                    }, { totalADD: 0, totalDEL: 0 });

                    // Calcula el total
                    const total = totalADD - totalDEL;
                    // Actualiza los estados globales
                    setCADD(totalADD);
                    setCDEL(totalDEL);
                    setCTOTAL(total);
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setListadoCompensado([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const editarCompensado = (val) => {
        setEditar(true);
        setFechaCompensado(val.FechaCompensado);
        settipoCompensado(val.TipoCompensado);
        setobsCompensado(val.ObsCompensado);
        setidCompensado(val.ID_Registro);
        setfechaToma(val.FechaToma);
    }

    const updateCompensado = async () => {
        const mensajeError = validarCompensado(fechaCompensado, tipoCompensado);
        if (mensajeError) {
            Swal.fire('Advertencia', mensajeError, 'warning');
            return; // Detiene la función si falla la validación
        }
        const datos = {
            idCompensado: idCompensado,
            fechaCompensado: fechaCompensado,
            tipoCompensado: tipoCompensado,
            obsCompensado: obsCompensado,
            usuarioRegistro: cookies.usuario,
            fechatoma: fechaToma
        };
        try {
            const response = await Axios.put(`${config.API_URL}/updateCompensado`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Compensados',
                Detalle: `Update de compensado trabajador ID: ${idTrabajadorSeleccionado}`,
                Estado: 'Éxito',
            });
            Swal.fire(
                '¡Éxito!',
                'El compensado se actualizo exitosamente',
                'success'
            );
            cargaCompensados(idTrabajadorSeleccionado);
            setFechaCompensado("");
            settipoCompensado("");
            setobsCompensado("");
            setfechaToma("");
            setEditar(false);
        } catch (error) {
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Compensados',
                Detalle: `Update de compensados trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    };

    const delCompensado = async (id) => {
        try {
            const result = await Swal.fire({
                title: '¡Cuidado!',
                html: '¿Está seguro de eliminar el registro de compensado?.',
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, eliminar!'
            });
            if (result.isConfirmed) {
                await Axios.delete(`${config.API_URL}/eliminaCompensado/${id}`);
                cargaCompensados(idTrabajadorSeleccionado);
                setFechaCompensado("");
                settipoCompensado("");
                setobsCompensado("");
                setfechaToma("");
                await registrarLog({
                    Usuario: cookies.usuario,
                    Modulo: 'Compensados',
                    Detalle: `Elimina compensado trabajador ID: ${idTrabajadorSeleccionado}`,
                    Estado: 'Éxito',
                });

                Swal.fire(
                    'Eliminado!',
                    'El registro de compensado ha sido eliminado exitosamente',
                    'success'
                );
            }
        } catch (error) {
            Swal.fire(
                '¡Algo salió mal!',
                'Hubo un error al eliminar el registro de compensado.',
                'error'
            );
            await registrarLog({
                Usuario: cookies.usuario, // Obtiene el usuario de las cookies
                Modulo: 'Compensados',  // Módulo relacionado
                Detalle: `Error al eliminar compensado ID: ${id} - ${error.message}`,  // Detalle del error
                Estado: 'Fallo',  // Estado de la operación
            });
            console.error("Error al eliminar compensado:", error);
        }
    };

    const getTrabajadores = () => {
        console.log("idEmpresa:", idEmpresa);  // Verifica que idEmpresa tiene el valor esperado
        console.log("intentando cargar trabajadores compensado");
        Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setTrabajadores(res.data); // Asegúrate de que sea un array
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setTrabajadores([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    useEffect(() => {
        getTrabajadores();
    }, []);
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-9">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Registro de compensados  de {NombreEmpresa}</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <span class="input-group-text" id="basic-addon1">Trabajador:</span>
                                                <select class="form-select" value={idTrabajadorSeleccionado} onChange={(e) => { setIdTrabajadorSeleccionado(e.target.value); seleccionTrue(e.target.value); setNombreTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].text) }} >
                                                    <option value="">Seleccione un trabajador:</option>
                                                    {trabajadores.map(trabajador => (
                                                        <option
                                                            key={trabajador.ID_Trabajador}
                                                            value={trabajador.ID_Trabajador}
                                                            name={trabajador.NombreCompleto}>
                                                            {trabajador.NombreCompleto}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <hr />
                                            <div class="card-footer text-muted">
                                                {
                                                    seleccion ?
                                                        <div>
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Fecha compensado:</span>
                                                                <input type="date" class="form-control" value={fechaCompensado} onChange={(event) => { setFechaCompensado(event.target.value); }} />
                                                                <span class="input-group-text" id="basic-addon1">Tipo Compensado:</span>
                                                                <select class="form-select" value={tipoCompensado} onChange={(event) => { settipoCompensado(event.target.value); }}>
                                                                    <option value="">Seleccione:</option>
                                                                    <option value="ADD">Agregar</option>
                                                                    <option value="DEL">Descontar</option>
                                                                </select>
                                                                {tipoCompensado === "DEL" && (
                                                                    <>
                                                                        <span className="input-group-text" id="basic-addon1">Fecha toma:</span>
                                                                        <input
                                                                            type="date"
                                                                            className="form-control"
                                                                            value={fechaToma}
                                                                            onChange={(event) => setfechaToma(event.target.value)}
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div class="input-group mb-3">
                                                                <span class="input-group-text" id="basic-addon1">Observación:</span>
                                                                <input type="text" class="form-control" value={obsCompensado} onChange={(event) => { setobsCompensado(event.target.value); }}></input>
                                                            </div>
                                                            {editar ? (
                                                                <div>
                                                                    <button class="btn btn-primary" onClick={updateCompensado}>Actualizar</button>
                                                                </div>
                                                            ) : (
                                                                <button class="btn btn-primary" onClick={ConfirmaRegistro}>Registrar</button>

                                                            )}
                                                        </div>
                                                        : <label></label>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <div class="col-xl-12 col-md-6 mb-4">
                                        <div class="card border-left-success shadow h-100 py-1">
                                            <div class="card-body">
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col mr-2">
                                                        <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                                            Dias acumulados</div>
                                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{cADD}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-md-6 mb-4">
                                        <div class="card border-left-danger shadow h-100 py-1">
                                            <div class="card-body">
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col mr-2">
                                                        <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                                            Dias tomados</div>
                                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{cDEL}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-md-6 mb-4">
                                        <div class="card border-left-info shadow h-100 py-1">
                                            <div class="card-body">
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col mr-2">
                                                        <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                                            Dias disponibles</div>
                                                        <div class="h5 mb-0 font-weight-bold text-gray-800">{cTOTAL}</div>
                                                    </div>
                                                    <div class="col-auto">
                                                        <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de compensados del trabajador</h6>
                                        </div>
                                        <div class="card-body">
                                            {
                                                seleccion ?
                                                    <div>
                                                        <div class="table-responsive">
                                                            <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                                <thead>
                                                                    <tr>
                                                                        <th>ID</th>
                                                                        <th>Fecha compensado</th>
                                                                        <th>Fecha toma</th>
                                                                        <th>Tipo compensado</th>
                                                                        <th>Observación</th>
                                                                        <th>Usuario registro</th>
                                                                        <th>Fecha registro</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        Array.isArray(listadoCompensado) && listadoCompensado.length > 0 ? (
                                                                            listadoCompensado.map((val, key) => (
                                                                                <tr key={key}>
                                                                                    <th scope="row">{val.ID_Registro}</th>
                                                                                    <td data-label="Fecha compensado">
                                                                                        {(() => {
                                                                                            const [year, month, day] = val.FechaCompensado.split('-');
                                                                                            return `${day}/${month}/${year}`;
                                                                                        })()}
                                                                                    </td>
                                                                                    <td data-label="Fecha toma">
                                                                                        {val.FechaToma === 'N/A' ? 'N/A' : moment(val.FechaToma).format('DD/MM/YYYY')}
                                                                                    </td>
                                                                                    <td data-label="Tipo compensado">
                                                                                        {val.TipoCompensado === 'ADD' ? 'Agrega' : val.TipoCompensado === 'DEL' ? 'Descuenta' : val.TipoCompensado}
                                                                                    </td>
                                                                                    <td data-label="Observación">{val.ObsCompensado}</td>
                                                                                    <td data-label="Usuario registro">{val.UsuarioRegistro}</td>
                                                                                    <td data-label="Fecha registro">{moment(val.FechaRegistro).format('DD/MM/YYYY - HH:mm')}</td>
                                                                                    <td data-label="Acciones">
                                                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                                                            <button type="button" onClick={() => { editarCompensado(val); }} className="btn btn-info">Seleccionar</button>
                                                                                            <button type="button" onClick={() => { delCompensado(val.ID_Registro); }} className="btn btn-danger">Eliminar</button>
                                                                                            <button className="btn btn-secondary" onClick={() => generarPDF(imageUrlPDF, nombreTrabajadorSeleccionado, moment(val.FechaToma).format('DD/MM/YYYY'), moment(val.FechaCompensado).format('DD/MM/YYYY'))}>Comprobante</button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <tr><td colSpan="15">No hay información de compensados para el trabajador seleccionado.</td></tr>
                                                                        )
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    : <label></label>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Compensados
