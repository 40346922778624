import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import useAuthRedirect from "./Componentes/auth";
import Axios from 'axios';
import Swal from 'sweetalert2';
import config from './Componentes/config';
import moment from 'moment';
import { registrarLog } from './Componentes/logService';

const cardImages = {
    DAFA: "/img/dafaLogo.png",
    FUGA: "/img/fugarLogo.png",
    INVE: "/img/idafaLogo.png",
    TRAN: "/img/extasisLogo.png"
  };


function Administracion() {
    useAuthRedirect();
    const [cookies] = useCookies(['selectedCard']);
    const imageUrl = cardImages[cookies.selectedCard]
    const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
    const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
    const [isInventario, setisIventatioCollapsed] = useState(true);
    const [isPrevencion, setisPrevencionCollapsed] = useState(true);
    const [isLogistica, setisLogisticaCollapsed] = useState(true);
    const toggleCollapse = (collapseSetter) => {
        collapseSetter(prevState => !prevState);
    };
    const getEmpresaName = (card) => {
        switch (card) {
            case 'FUGA':
                return 'FUGAR SERVICIOS INDUSTRIALES SPA';
            case 'DAFA':
                return 'DAFA FACILITY SERVICES SPA';
            default:
                return ''; // Retorna un string vacío o un valor por defecto si no coincide
        }
    };
    // Estados para el componente Trabajadores
    const [empresa, setEmpresa] = useState(getEmpresaName(cookies.selectedCard));
    const [listadoVacaciones, setlistadoVacaciones] = useState([]);

    const getSolicitudesVacaciones = () => {
        console.log("intentando cargar solicitudes de vacaciones panel adm");
        Axios.get(`${config.API_URL}/cargaSolicitudesVacaciones`)
            .then(res => {
                console.log(res.data); // Verifica la estructura de la respuesta
                if (Array.isArray(res.data)) {
                    setlistadoVacaciones(res.data); // Asegúrate de que sea un array
                    console.log(res.data);
                } else {
                    console.error('La respuesta no es un array:', res.data);
                    setlistadoVacaciones([]); // Establece un array vacío si no es un array
                }
            })
            .catch(err => {
                console.error("Error al obtener los datos:", err);
            });
    }

    const EstadoVacaciones = async (estado, NombreTrabajador, id) => { 
        const datos = {
            Estado: estado, // Incluimos el estado (Aprobado o Rechazado)
            Aprueba: cookies.usuario,
            id: id
        };
    
        try {
            const response = await Axios.put(`${config.API_URL}/EstadoVacaciones`, datos, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
    
            // Registramos el log con el estado
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Administracion',
                Detalle: `Cambio estado vacaciones trabajador: ${NombreTrabajador} - Estado: ${estado}`,
                Estado: 'Éxito',
            });
    
            // Mensaje de éxito con el estado
            Swal.fire(
                '¡Éxito!',
                `La solicitud se ${estado === 'Aprobado' ? 'aprobo' : 'rechazo'} exitosamente`,
                'success'
            );
        } catch (error) {
            // Registramos el log en caso de error
            await registrarLog({
                Usuario: cookies.usuario,
                Modulo: 'Administracion',
                Detalle: `Error al intentar el estado: ${estado}  del trabajador: ${NombreTrabajador} - Error: ${error.message || error}`,
                Estado: 'Fallo',
            });
    
            // Mensaje de error
            Swal.fire(
                '¡Algo pasó!',
                'Hubo un error al enviar la petición.',
                'error'
            );
        }
    };




    useEffect(() => {
        getSolicitudesVacaciones();
    }, []);
    return (
        <body id="page-top">
            <div id="wrapper">
                <Sidebar
                    imageUrl={imageUrl}
                    isComponentsCollapsed={isComponentsCollapsed}
                    toggleCollapse={toggleCollapse}
                    setIsComponentsCollapsed={setIsComponentsCollapsed}
                    isUtilitiesCollapsed={isUtilitiesCollapsed}
                    setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
                    isInventario={isInventario}
                    setisIventatioCollapsed={setisIventatioCollapsed}
                    isPrevencion={isPrevencion}
                    setisPrevencionCollapsed={setisPrevencionCollapsed}
                    isLogistica={isLogistica}
                    setisLogisticaCollapsed={setisLogisticaCollapsed}
                />
                <div id="content-wrapper" class="d-flex flex-column">
                    <div id="content">
                        <UserPage cookies={cookies} />
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card shadow mb-4">
                                        <div class="card-header py-3">
                                            <h6 class="m-0 font-weight-bold text-primary">Listado de solicitudes pendientes de vacaciones</h6>
                                        </div>
                                        <div class="card-body">
                                            <div class="input-group mb-3">
                                                <div class="col-md-12">
                                                    <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                                        <thead>
                                                            <tr>
                                                                <th>ID</th>
                                                                <th>Fecha solicitud</th>
                                                                <th>Solicitante</th>
                                                                <th>Trabajador</th>
                                                                <th>Dias solicitados</th>
                                                                <th>Periodo</th>
                                                                <th>Observacion</th>
                                                                <th>Acciones</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Array.isArray(listadoVacaciones) && listadoVacaciones.length > 0 ? (
                                                                    listadoVacaciones.map((val, key) => (
                                                                        <tr key={key}>
                                                                            <th scope="row">{val.ID_Solicitud}</th>
                                                                            <td>{moment(val.Fecha_Solicitud).format('DD/MM/YYYY')}</td>
                                                                            <td>{val.Solicitante}</td>
                                                                            <td>{val.Nombre_Completo}</td>
                                                                            <td>{val.Dias_Solicitados}</td>
                                                                            <td>{moment(val.Fecha_Inicio).format('DD/MM/YYYY')} al {moment(val.Fecha_Fin).format('DD/MM/YYYY')}</td>
                                                                            <td>{val.Observacion}</td>
                                                                            <td data-label="Acciones">
                                                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                                                    <button type="button" className="btn btn-info"  onClick={() => EstadoVacaciones("Aprobado", val.Nombre_Completo, val.ID_Solicitud)}>Aprobar</button>
                                                                                    <button type="button" className="btn btn-danger" onClick={() => EstadoVacaciones("Rechazado", val.Nombre_Completo,  val.ID_Solicitud)}>Rechazar</button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <tr><td colSpan="15">No hay información de solicitudes pendientes.</td></tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </body>
    )
}

export default Administracion
