// GenerarPDF.js
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const generarPDF = async (Empresa, RutEmpresa, NombreTrabajador, RutTrabajador, diasVacaciones, fechainicio, fechafin) => {
    
    const convertImageToBase64 = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();  // Convertir a blob
        const reader = new FileReader();
    
        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);  // Resolver con base64
            reader.onerror = reject;
            reader.readAsDataURL(blob);  // Leer la imagen como base64
        });
    };
    const logo64 = await convertImageToBase64("/img/connectLogo.png");
    const obtenerFechaActual = () => {
        const fecha = new Date();
        const dia = String(fecha.getDate()).padStart(2, '0'); // Obtiene el día y agrega un 0 si es menor a 10
        const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11, por eso se suma 1
        const anio = fecha.getFullYear(); // Obtiene el año completo
        return `${dia}/${mes}/${anio}`; // Construye el string con el formato deseado
    };
    const doc = new jsPDF();
    // Título del PDF
    doc.setFontSize(16);
    // Crear la tabla
autoTable(doc, {
    startY: 15, // Posición de inicio de la tabla
    body: [
        [''],  // Fila 1 (Título)
        [''],  // Fila 2
        [{ content: '', image: logo64, imageWidth: 100, imageHeight: 100, align: 'center' }],
    ],
    theme: 'grid', // Tema de la tabla, con bordes visibles
    styles: {
        halign: 'center',  // Alineación horizontal para el título
        valign: 'middle',  // Alineación vertical
        cellPadding: 18,    // Reducir relleno para reducir espacio blanco
        lineColor: [0, 0, 0], // Color de borde
        lineWidth: 0.5,    // Ancho del borde
    },
    headStyles: {
        fillColor: [255, 255, 255], // Fondo blanco para los encabezados
        textColor: [0, 0, 0],       // Color de texto negro
        fontSize: 16,               // Título más grande
        fontStyle: 'bold',          // Negrita en el encabezado
        halign: 'center',           // Alinear al centro
    },
    columnStyles: {
        0: { 
            cellWidth: 'auto', 
            fillColor: [255, 255, 255], 
            textColor: [0, 0, 0],
            halign: 'left'  // Alinear a la izquierda para "Representante legal"
        },  
        1: {
            halign: 'right',  // Alinear a la derecha para "Firma trabajador"
            cellWidth: 'auto'
        }
    },
    rowHeight: 5,
    margin: { top: 30 },
    didDrawCell: (data) => {
        if (data.row.index === 2) { // Fila 3 (Representante legal y Firma trabajador)
            data.cell.height = 30;
            doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 25);
            if (data.column.index === 0) { // Representante legal
                doc.setFontSize(12);
                doc.text('Representante legal', data.cell.x + 25, data.cell.y + 30);
            }
            if (data.column.index === 0) { // Firma trabajador
                doc.setFontSize(12);
                doc.text('Firma trabajador', data.cell.x + 120, data.cell.y + 30);
            }
        }

        if (data.row.index === 1) {
            const text = 'En coronel, a ' + obtenerFechaActual() + ', la empresa ' + Empresa + ', rut ' + RutEmpresa + ' manifiesta que el trabajador Don ' + NombreTrabajador  + 
            ', rut ' + RutTrabajador + ' cuenta con ' + diasVacaciones + ' días hábiles de feriado legal, correspondiente a sus vacaciones proporcionales, las que hará uso, ' + 
            'se le otorgarán '+ diasVacaciones +' días desde el ' + fechainicio + ' al ' + fechafin + '(inclusive).';
            doc.setFontSize(14);
            // Ajustar el texto a la celda
            const maxWidth = data.cell.width - 30; // Deja espacio para el padding y márgenes
            const lines = doc.splitTextToSize(text, maxWidth);
        
            // Justificar cada línea (ajustar espaciado)
            const lineHeight = 5; // Espacio entre líneas
            const startY = data.cell.y + 10; // Inicio en Y para el texto
            
            lines.forEach((line, index) => {
                // Calcular la posición Y de cada línea
                const y = startY + index * lineHeight;
        
                // Justificar manualmente usando espaciado entre palabras
                doc.text(line, data.cell.x + 20, y, { align: 'justify' });
            });
        }

        // Cambiar el estilo de la primera celda (título)
        if (data.row.index === 0 && data.column.index === 0) {
            doc.setFontSize(20); // Aumentar tamaño de la fuente del título
            doc.setFont('Arial', 'bold'); // Fuente en negrita
            const title = 'Comprobante feriado legal / Vacaciones proporcionales';
            // Escribir el título centrado
            doc.text(title, doc.internal.pageSize.width / 2, data.cell.y + 20, { align: 'center' }); 
        }
    }
});

const lineY = doc.lastAutoTable.finalY + 10;  // Usar la posición final de la tabla más un margen
    doc.setLineDash([4, 2]); 
    doc.line(10, lineY, doc.internal.pageSize.width - 10, lineY); // Línea horizontal a lo largo de la página
    // Desactivar el estilo de línea segmentada para la segunda tabla
    doc.setLineDash([]); // Restablecer el estilo de línea a sólido

autoTable(doc, {
    startY: 155, // Posición de inicio de la tabla
    body: [
        [''],  // Fila 1 (Título)
        [''],  // Fila 2
        [{ content: '', image: logo64, imageWidth: 100, imageHeight: 100, align: 'center' }],
    ],
    theme: 'grid', // Tema de la tabla, con bordes visibles
    styles: {
        halign: 'center',  // Alineación horizontal para el título
        valign: 'middle',  // Alineación vertical
        cellPadding: 18,    // Reducir relleno para reducir espacio blanco
        lineColor: [0, 0, 0], // Color de borde
        lineWidth: 0.5,    // Ancho del borde
    },
    headStyles: {
        fillColor: [255, 255, 255], // Fondo blanco para los encabezados
        textColor: [0, 0, 0],       // Color de texto negro
        fontSize: 16,               // Título más grande
        fontStyle: 'bold',          // Negrita en el encabezado
        halign: 'center',           // Alinear al centro
    },
    columnStyles: {
        0: { 
            cellWidth: 'auto', 
            fillColor: [255, 255, 255], 
            textColor: [0, 0, 0],
            halign: 'left'  // Alinear a la izquierda para "Representante legal"
        },  
        1: {
            halign: 'right',  // Alinear a la derecha para "Firma trabajador"
            cellWidth: 'auto'
        }
    },
    rowHeight: 5,
    margin: { top: 30 },
    didDrawCell: (data) => {
        if (data.row.index === 2) { // Fila 3 (Representante legal y Firma trabajador)
            data.cell.height = 30;
            doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 25);
            if (data.column.index === 0) { // Representante legal
                doc.setFontSize(12);
                doc.text('Representante legal', data.cell.x + 25, data.cell.y + 30);
            }
            if (data.column.index === 0) { // Firma trabajador
                doc.setFontSize(12);
                doc.text('Firma trabajador', data.cell.x + 120, data.cell.y + 30);
            }
        }

        if (data.row.index === 1) {
            const text = 'En coronel, a ' + obtenerFechaActual() + ', la empresa ' + Empresa + ', rut ' + RutEmpresa + ' manifiesta que el trabajador Don ' + NombreTrabajador  + 
            ', rut ' + RutTrabajador + ' cuenta con ' + diasVacaciones + ' días hábiles de feriado legal, correspondiente a sus vacaciones proporcionales, las que hará uso, ' + 
            'se le otorgarán '+ diasVacaciones +' días desde el ' + fechainicio + ' al ' + fechafin + '(inclusive).';
            doc.setFontSize(14);
            // Ajustar el texto a la celda
            const maxWidth = data.cell.width - 30; // Deja espacio para el padding y márgenes
            const lines = doc.splitTextToSize(text, maxWidth);
        
            // Justificar cada línea (ajustar espaciado)
            const lineHeight = 5; // Espacio entre líneas
            const startY = data.cell.y + 10; // Inicio en Y para el texto
            
            lines.forEach((line, index) => {
                // Calcular la posición Y de cada línea
                const y = startY + index * lineHeight;
        
                // Justificar manualmente usando espaciado entre palabras
                doc.text(line, data.cell.x + 20, y, { align: 'justify' });
            });
        }

        // Cambiar el estilo de la primera celda (título)
        if (data.row.index === 0 && data.column.index === 0) {
            doc.setFontSize(20); // Aumentar tamaño de la fuente del título
            doc.setFont('Arial', 'bold'); // Fuente en negrita
            const title = 'Comprobante feriado legal / Vacaciones proporcionales';
            // Escribir el título centrado
            doc.text(title, doc.internal.pageSize.width / 2, data.cell.y + 20, { align: 'center' }); 
        }
    }
});

    // Guardar el PDF
    doc.save("Comprobante vacaciones " + NombreTrabajador + ".pdf");
};

export default generarPDF;



