// GenerarPDF.js
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const generarPDF = async (logo, nombreTrabajador, fechaToma, diaCompensado) => {
    // Función para convertir la imagen en base64
    const convertImageToBase64 = async (imageUrl) => {
        const response = await fetch(imageUrl);
        const blob = await response.blob();  // Convertir a blob
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = () => resolve(reader.result);  // Resolver con base64
            reader.onerror = reject;
            reader.readAsDataURL(blob);  // Leer la imagen como base64
        });
    };

    const doc = new jsPDF();
    const logo64 = await convertImageToBase64(logo);
    let ruta = "";
    if (logo.includes("fugar")) {
        ruta = "/img/d34rdf2hi56ocz.png";
    } else {
        ruta = "/img/fgd78fgd12.png";
    }
    const firma = await convertImageToBase64(ruta);
    // Título del PDF
    doc.setFontSize(16);
    // Crear la tabla
    autoTable(doc, {
        startY: 30, // Posición de inicio de la tabla
        body: [
            // Agregar imagen en la primera celda del encabezado
            [{ content: '', image: logo64, imageWidth: 100, imageHeight: 100, align: 'center' }, 'COMPROBANTE COMPENSADO'],
            ['NOMBRE TRABAJADOR', nombreTrabajador],  // Fila 2
            ['Tipo comprobante', "C.C trabajador"],   // Fila 3
            ['', ''],
        ],
        theme: 'grid', // Tema de la tabla, con bordes visibles
        styles: {
            halign: 'center',  // Alineación horizontal
            valign: 'middle',  // Alineación vertical
            cellPadding: 10,   // Aumentar relleno para aumentar el tamaño de la celda
            lineColor: [0, 0, 0], // Color de borde
            lineWidth: 0.5,       // Ancho del borde
        },
        headStyles: {
            fillColor: [255, 255, 255], // Fondo blanco para los encabezados
            textColor: [0, 0, 0],       // Color de texto negro
        },
        columnStyles: {
            0: { cellWidth: 85, fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Columna 1 (con imagen)
            1: { cellWidth: 70, fillColor: [255, 255, 255], textColor: [0, 0, 0] }  // Columna 2
        },
        didDrawCell: (data) => {
            if (data.row.index === 0 && data.column.index === 0) {
                // Modificar la altura de la celda donde aparece la imagen (en la primera fila y columna)
                doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 25); // Insertar la imagen
                data.cell.height = 30; // Modificar la altura de la celda de la primera columna (ajustar según sea necesario)
            }
            if (data.row.index === 3 && data.column.index === 0) {
                // Subdividir la columna en dos celdas
                doc.text('FIRMA TRABAJADOR', data.cell.x + 25, data.cell.y + 22); // Colocar FIRMA TRABAJADOR
                data.cell.height = 30; // Ajustar altura de la celda de firma
            }
            if (data.row.index === 3 && data.column.index === 1) {
                // Dividir la celda en dos filas horizontales
                const middleY = data.cell.y + data.cell.height / 2;  // Punto medio en la dirección Y

                // Escribir el texto en la primera mitad de la celda
                doc.text('DIA COMPENSADO: ' + diaCompensado, data.cell.x + 2, data.cell.y + 10);

                // Escribir el texto en la segunda mitad de la celda
                doc.text('DIA TOMA COMPENSADO: ' + fechaToma, data.cell.x + 2, middleY + 10);

                // Dibujar la línea divisoria entre las dos mitades (horizontal)
                doc.setLineWidth(0.5);
                doc.line(data.cell.x, middleY, data.cell.x + data.cell.width, middleY); // Línea horizontal
            }
        }
    });

    const lineY = doc.lastAutoTable.finalY + 10;  // Usar la posición final de la tabla más un margen
    doc.setLineDash([4, 2]);
    doc.line(10, lineY, doc.internal.pageSize.width - 10, lineY); // Línea horizontal a lo largo de la página
    // Desactivar el estilo de línea segmentada para la segunda tabla
    doc.setLineDash([]); // Restablecer el estilo de línea a sólido

    autoTable(doc, {
        startY: 150, // Posición de inicio de la tabla
        body: [
            // Agregar imagen en la primera celda del encabezado
            [{ content: '', image: logo64, imageWidth: 100, imageHeight: 100, align: 'center' }, 'COMPROBANTE COMPENSADO'],
            ['NOMBRE TRABAJADOR', nombreTrabajador],  // Fila 2
            ['Tipo comprobante', "C.C empleador"],   // Fila 3
            ['', ''],
        ],
        theme: 'grid', // Tema de la tabla, con bordes visibles
        styles: {
            halign: 'center',  // Alineación horizontal
            valign: 'middle',  // Alineación vertical
            cellPadding: 10,   // Aumentar relleno para aumentar el tamaño de la celda
            lineColor: [0, 0, 0], // Color de borde
            lineWidth: 0.5,       // Ancho del borde
        },
        headStyles: {
            fillColor: [255, 255, 255], // Fondo blanco para los encabezados
            textColor: [0, 0, 0],       // Color de texto negro
        },
        columnStyles: {
            0: { cellWidth: 85, fillColor: [255, 255, 255], textColor: [0, 0, 0] }, // Columna 1 (con imagen)
            1: { cellWidth: 70, fillColor: [255, 255, 255], textColor: [0, 0, 0] }  // Columna 2
        },
        didDrawCell: (data) => {
            if (data.row.index === 0 && data.column.index === 0) {
                // Modificar la altura de la celda donde aparece la imagen (en la primera fila y columna)
                doc.addImage(logo64, 'PNG', data.cell.x + 2, data.cell.y + 2, 80, 25); // Insertar la imagen
                data.cell.height = 30; // Modificar la altura de la celda de la primera columna (ajustar según sea necesario)
            }
            if (data.row.index === 3 && data.column.index === 0) {
                // Subdividir la columna en dos celdas
                doc.addImage(firma, 'PNG', data.cell.x + 12, data.cell.y + 2, 60, 20);
                doc.text('FIRMA EMPLEADOR', data.cell.x + 25, data.cell.y + 22); // Colocar FIRMA TRABAJADOR
                data.cell.height = 30; // Ajustar altura de la celda de firma
            }
            if (data.row.index === 3 && data.column.index === 1) {
                // Dividir la celda en dos filas horizontales
                const middleY = data.cell.y + data.cell.height / 2;  // Punto medio en la dirección Y

                // Escribir el texto en la primera mitad de la celda
                doc.text('DIA COMPENSADO: ' + diaCompensado, data.cell.x + 2, data.cell.y + 10);

                // Escribir el texto en la segunda mitad de la celda
                doc.text('DIA TOMA COMPENSADO: ' + fechaToma, data.cell.x + 2, middleY + 10);

                // Dibujar la línea divisoria entre las dos mitades (horizontal)
                doc.setLineWidth(0.5);
                doc.line(data.cell.x, middleY, data.cell.x + data.cell.width, middleY); // Línea horizontal
            }
        }
    });

    // Guardar el PDF
    doc.save("Comprobante compensado " + nombreTrabajador + " " + diaCompensado + ".pdf");
};

export default generarPDF;
