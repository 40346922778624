import React, { useState, useEffect } from "react"
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import Sidebar from './SideBar';
import UserPage from "./UserPage";
import Swal from 'sweetalert2';
import { validarPermiso } from './Validations/TrabajadoresValidation';
import generarPDF from './Documentos/Permiso';
import config from './Componentes/config';
import useAuthRedirect from "./Componentes/auth";
import { registrarLog } from './Componentes/logService';
import moment from 'moment';

const cardImages = {
  DAFA: "/img/dafaLogo.png",
  FUGA: "/img/fugarLogo.png",
  INVE: "/img/idafaLogo.png",
  TRAN: "/img/extasisLogo.png"
};

const cardImagesPDF = {
  DAFA: "/img/dafaLogoPDF.png",
  FUGA: "/img/fugarLogoPDF.png",
  INVE: "/img/idafaLogoPDF.png",
  TRAN: "/img/extasisLogoPDF.png"
};

function Permisos() {
  useAuthRedirect();
  const [cookies] = useCookies(['selectedCard']);
  const imageUrl = cardImages[cookies.selectedCard]
  const imageUrlPDF = cardImagesPDF[cookies.selectedCard]
  const idEmpresa = cookies.idEmpresa;
  const NombreEmpresa = cookies.NombreEmpresa;
  const [isComponentsCollapsed, setIsComponentsCollapsed] = useState(true);
  const [isUtilitiesCollapsed, setIsUtilitiesCollapsed] = useState(true);
  const [isInventario, setisIventatioCollapsed] = useState(true);
  const [isPrevencion, setisPrevencionCollapsed] = useState(true);
  const [isLogistica, setisLogisticaCollapsed] = useState(true);
  const toggleCollapse = (collapseSetter) => {
    collapseSetter(prevState => !prevState);
  };
  const [trabajadores, setTrabajadores] = useState([]);
  const [idTrabajadorSeleccionado, setIdTrabajadorSeleccionado] = useState('');
  const [nombreTrabajadorSeleccionado, setNombreTrabajadorSeleccionado] = useState('');
  const [fechaPermiso, setfechaPermiso] = useState("");
  const [tipoPermiso, settipoPermiso] = useState("");
  const [obsPermiso, setobsPermiso] = useState("");
  const [listadoPermiso, setlistadoPermiso] = useState([]);
  const [seleccion, setSeleccion] = useState(false);
  const [Rango, setRango] = useState(false);
  const [DC, setDC] = useState("");
  const [RH, setRH] = useState("");
  const [dTotal, setdTotal] = useState("");
  const [editar, setEditar] = useState(false);
  const [idPermiso, setidPermiso] = useState("");
  const [horaDesde, sethoraDesde] = useState("");
  const [horaHasta, sethoraHasta] = useState("");
  const [goceSueldo, setgoceSueldo] = useState("");
  const ConfirmaRegistro = () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, registrar!'
    }).then((result) => {
      if (result.isConfirmed) {
        registraPermiso();
      }
    });
  }

  const handleSelectChange = (event) => {
    const value = event.target.value;
    settipoPermiso(value);
    setRango(value === "RH");
  };

  const seleccionTrue = (ID) => {
    setSeleccion(true);
    cargaPermisos(ID);
  }

  const registraPermiso = async () => {
    const mensajeError = validarPermiso(fechaPermiso, tipoPermiso, horaDesde, horaHasta);
    if (mensajeError) {
      Swal.fire('Advertencia', mensajeError, 'warning');
      return; // Detiene la función si falla la validación
    }
    const datos = {
      idTrabajador: idTrabajadorSeleccionado,
      fechaPermiso: fechaPermiso,
      tipoPermiso: tipoPermiso,
      obsPermiso: obsPermiso,
      usuarioRegistro: cookies.usuario,
      horaDesde: Rango ? horaDesde : "N/A",
      horaHasta: Rango ? horaHasta : "N/A",
      goceSueldo: goceSueldo
    };
    try {
      const response = await Axios.post(`${config.API_URL}/registraPermiso`, datos, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // Registrar el log (llamamos a la función desacoplada)
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Permisos',
        Detalle: `Registro de permiso trabajador ID: ${idTrabajadorSeleccionado}`,
        Estado: 'Éxito',
      });
      Swal.fire(
        '¡Éxito!',
        'El permiso se registro exitosamente',
        'success'
      );
      cargaPermisos(idTrabajadorSeleccionado);
      setfechaPermiso("");
      settipoPermiso("");
      setobsPermiso("");
      sethoraDesde("");
      setgoceSueldo("");
      sethoraHasta("");
      setRango(false);
    } catch (error) {
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Permisos',
        Detalle: `Registro de permiso trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
        Estado: 'Fallo',
      });
      Swal.fire(
        '¡Algo pasó!',
        'Hubo un error al enviar la petición.',
        'error'
      );
    }
  }

  const cargaPermisos = (ID) => {
    Axios.get(`${config.API_URL}/cargaPermiso?id=${ID}`)
      .then(res => {
        console.log(res.data); // Verifica la estructura de la respuesta
        if (Array.isArray(res.data)) {
          setlistadoPermiso(res.data); // Asegúrate de que sea un array
          const { DC, RH } = res.data.reduce((totals, item) => {
            if (item.TipoPermiso === 'DC') {
              totals.DC += 1;
            } else if (item.TipoPermiso === 'RH') {
              totals.RH += 1;
            }
            return totals;
          }, { DC: 0, RH: 0 });

          // Calcula el total
          const total = DC + RH;
          // Actualiza los estados globales
          setDC(DC);
          setRH(RH);
          setdTotal(total);
        } else {
          console.error('La respuesta no es un array:', res.data);
          setlistadoPermiso([]); // Establece un array vacío si no es un array
        }
      })
      .catch(err => {
        console.error("Error al obtener los datos:", err);
      });
  }

  const editarPermiso = (val) => {
    setEditar(true);
    setfechaPermiso(val.FechaPermiso);
    settipoPermiso(val.TipoPermiso);
    setobsPermiso(val.ObsPermiso);
    setgoceSueldo(val.GoceSueldo);
    setidPermiso(val.ID_Registro);
    console.log(val.TipoPermiso)
    if (val.TipoPermiso === 'RH') {
      setRango(true);
      const horaDesde = val.rango.substring(val.rango.indexOf('las ') + 4, val.rango.indexOf('y las') - 1);
      const horaHasta = val.rango.substring(val.rango.indexOf('y las ') + 6);
      sethoraDesde(horaDesde);
      sethoraHasta(horaHasta);
    }
  }

  const updatePermiso = async () => {
    const mensajeError = validarPermiso(fechaPermiso, tipoPermiso, horaDesde, horaHasta);
    if (mensajeError) {
      Swal.fire('Advertencia', mensajeError, 'warning');
      return; // Detiene la función si falla la validación
    }
    const datos = {
      idPermiso: idPermiso,
      fechaPermiso: fechaPermiso,
      tipoPermiso: tipoPermiso,
      obsPermiso: obsPermiso,
      horaDesde: horaDesde,
      horaHasta: horaHasta,
      goceSueldo: goceSueldo,
      usuarioRegistro: cookies.usuario
    };
    try {
      const response = await Axios.put(`${config.API_URL}/updatePermiso`, datos, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Permisos',
        Detalle: `update de permiso trabajador ID: ${idTrabajadorSeleccionado}`,
        Estado: 'Éxito',
      });
      Swal.fire(
        '¡Éxito!',
        'El compensado se actualizo exitosamente',
        'success'
      );
      cargaPermisos(idTrabajadorSeleccionado);
      setfechaPermiso("");
      settipoPermiso("");
      setobsPermiso("");
      setgoceSueldo("");
      setEditar(false);
      setRango(false);
    } catch (error) {
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Permisos',
        Detalle: `Update de permiso trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
        Estado: 'Fallo',
      });
      Swal.fire(
        '¡Algo pasó!',
        'Hubo un error al enviar la petición.',
        'error'
      );
    }
  };

  const delPermiso = async (id) => {
    try {
      // Mostramos la alerta de confirmación
      const result = await Swal.fire({
        title: '¡Cuidado!',
        html: '¿Está seguro de eliminar el registro de permiso?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminar!'
      });
      if (result.isConfirmed) {
        await Axios.delete(`${config.API_URL}/eliminaPermiso/${id}`);
        cargaPermisos(idTrabajadorSeleccionado);
        setfechaPermiso("");
        settipoPermiso("");
        setobsPermiso("");
        await registrarLog({
          Usuario: cookies.usuario,
          Modulo: 'Permisos',
          Detalle: `Eliminar permiso trabajador ID: ${idTrabajadorSeleccionado}`,
          Estado: 'Éxito',
        });
        Swal.fire(
          'Eliminado!',
          'El registro de permiso ha sido eliminado exitosamente.',
          'success'
        );
      }
    } catch (error) {
      await registrarLog({
        Usuario: cookies.usuario,
        Modulo: 'Permisos',
        Detalle: `Eliminar permiso trabajador ID: ${idTrabajadorSeleccionado} - Error: ${error.message || error}`,
        Estado: 'Fallo',
      });
      Swal.fire(
        '¡Algo pasó!',
        'Hubo un error al eliminar el registro de permiso.',
        'error'
      );
    }
  };


  useEffect(() => {
    Axios.get(`${config.API_URL}/CargaTrabajadores?id=${idEmpresa}`)
      .then(response => {
        setTrabajadores(response.data);
      })
      .catch(error => {
        console.error('Error al cargar los trabajadores:', error);
      });
  }, []);
  return (
    <body id="page-top">
      <div id="wrapper">
        <Sidebar
          imageUrl={imageUrl}
          isComponentsCollapsed={isComponentsCollapsed}
          toggleCollapse={toggleCollapse}
          setIsComponentsCollapsed={setIsComponentsCollapsed}
          isUtilitiesCollapsed={isUtilitiesCollapsed}
          setIsUtilitiesCollapsed={setIsUtilitiesCollapsed}
          isInventario={isInventario}
          setisIventatioCollapsed={setisIventatioCollapsed}
          isPrevencion={isPrevencion}
          setisPrevencionCollapsed={setisPrevencionCollapsed}
          isLogistica={isLogistica}
          setisLogisticaCollapsed={setisLogisticaCollapsed}
        />
        <div id="content-wrapper" class="d-flex flex-column">
          <div id="content">
            <UserPage cookies={cookies} />
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-8">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Registro de permisos  de {NombreEmpresa}</h6>
                    </div>
                    <div class="card-body">
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Trabajador:</span>
                        <select class="form-select" value={idTrabajadorSeleccionado} onChange={(e) => { setIdTrabajadorSeleccionado(e.target.value); seleccionTrue(e.target.value); setNombreTrabajadorSeleccionado(e.target.options[e.target.selectedIndex].text) }} >
                          <option value="">Seleccione un trabajador:</option>
                          {trabajadores.map(trabajador => (
                            <option
                              key={trabajador.ID_Trabajador}
                              value={trabajador.ID_Trabajador}
                              name={trabajador.NombreCompleto}>
                              {trabajador.NombreCompleto}
                            </option>
                          ))}
                        </select>
                      </div>
                      <hr />
                      <div class="card-footer text-muted">
                        {
                          seleccion ?
                            <div>
                              <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon1">Fecha permiso:</span>
                                <input type="date" class="form-control" value={fechaPermiso} onChange={(event) => { setfechaPermiso(event.target.value); }} />
                                <span class="input-group-text" id="basic-addon1">Tipo permiso:</span>
                                <select class="form-select" value={tipoPermiso} onChange={handleSelectChange}>
                                  <option value="">Seleccione:</option>
                                  <option value="DC">Dia completo</option>
                                  <option value="RH">Rango hora</option>
                                </select>
                                <span class="input-group-text" id="basic-addon1">Goce sueldo:</span>
                                <select class="form-select" value={goceSueldo} onChange={(event) => { setgoceSueldo(event.target.value); }}>
                                  <option value="">Seleccione:</option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {Rango ? (
                                <div class="input-group mb-3">
                                  <span class="input-group-text" id="basic-addon1">Hora desde:</span>
                                  <input type="time" class="form-control" value={horaDesde} onChange={(event) => { sethoraDesde(event.target.value); }} />
                                  <span class="input-group-text" id="basic-addon1">Hora hasta:</span>
                                  <input type="time" class="form-control" value={horaHasta} onChange={(event) => { sethoraHasta(event.target.value); }} />
                                </div>
                              ) : (
                                <label></label>
                              )}
                              <div class="input-group mb-3">
                                <span class="input-group-text" id="basic-addon1">Observación:</span>
                                <input type="text" class="form-control" value={obsPermiso} onChange={(event) => { setobsPermiso(event.target.value); }}></input>
                              </div>
                              {editar ? (
                                <div>
                                  <button class="btn btn-primary" onClick={updatePermiso}>Actualizar</button>
                                </div>
                              ) : (
                                <button class="btn btn-primary" onClick={ConfirmaRegistro}>Registrar</button>

                              )}
                            </div>
                            : <label></label>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-xl-12 col-md-6 mb-4">
                    <div class="card border-left-success shadow h-100 py-1">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                              Permisos dia completo</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{DC}</div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-md-6 mb-4">
                    <div class="card border-left-danger shadow h-100 py-1">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-danger text-uppercase mb-1">
                              Permisos por hora</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{RH}</div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-12 col-md-6 mb-4">
                    <div class="card border-left-info shadow h-100 py-1">
                      <div class="card-body">
                        <div class="row no-gutters align-items-center">
                          <div class="col mr-2">
                            <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                              Permisos totales</div>
                            <div class="h5 mb-0 font-weight-bold text-gray-800">{dTotal}</div>
                          </div>
                          <div class="col-auto">
                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <h6 class="m-0 font-weight-bold text-primary">Listado de permisos del trabajador</h6>
                    </div>
                    <div class="card-body">
                      {
                        seleccion ?
                          <div>
                            <div class="table-responsive">
                              <table class="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Fecha permiso</th>
                                    <th>Tipo permiso</th>
                                    <th>Rango</th>
                                    <th>Observación</th>
                                    <th>Usuario registro</th>
                                    <th>Fecha registro</th>
                                    <th>Goce sueldo</th>
                                    <th>Acciones</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    Array.isArray(listadoPermiso) && listadoPermiso.length > 0 ? (
                                      listadoPermiso.map((val, key) => (
                                        <tr key={key}>
                                          <th scope="row">{val.ID_Registro}</th>
                                          <td data-label="Fecha permiso">
                                            {(() => {
                                              const [year, month, day] = val.FechaPermiso.split('-');
                                              return `${day}/${month}/${year}`;
                                            })()}
                                          </td>
                                          <td data-label="Tipo permiso">
                                            {val.TipoPermiso === 'DC' ? 'Dia completo' : val.TipoPermiso === 'RH' ? 'Rango hora' : val.TipoPermiso}
                                          </td>
                                          <td data-label="Rango">
                                            {val.TipoPermiso === "RH" ? val.rango : "N/A"}
                                          </td>
                                          <td data-label="Observación">{val.ObsPermiso}</td>
                                          <td data-label="Usuario registro">{val.UsuarioRegistro}</td>
                                          <td data-label="Fecha registro">{moment(val.FechaRegistro).format('DD/MM/YYYY - HH:mm')}</td>
                                          <td data-label="Goce Sueldo">{val.GoceSueldo}</td>
                                          <td data-label="Acciones">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                              <button type="button" onClick={() => { editarPermiso(val); }} className="btn btn-info">Seleccionar</button>
                                              <button type="button" onClick={() => { delPermiso(val.ID_Registro); }} className="btn btn-danger">Eliminar</button>
                                              <button className="btn btn-secondary" onClick={() => generarPDF(imageUrlPDF, nombreTrabajadorSeleccionado,  moment(val.FechaPermiso).format('DD/MM/YYYY'), val.TipoPermiso, val.rango.substring(val.rango.indexOf('las ') + 4, val.rango.indexOf('y las') - 1), val.rango.substring(val.rango.indexOf('y las ') + 6), val.GoceSueldo)}>Comprobante</button>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr><td colSpan="15">No hay información de compensados para el trabajador seleccionado.</td></tr>
                                    )
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                          : <label></label>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  )
}

export default Permisos
